import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import App from './App';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';

import { composeWithDevTools } from 'redux-devtools-extension';
import { RecoilRoot } from 'recoil';

const store = createStore(
	rootReducer,
	load(),
	composeWithDevTools(applyMiddleware(thunk, save()))
);

const MySite = () => {
	return (
		<Provider store={store}>
			<RecoilRoot>
				<App />
			</RecoilRoot>
		</Provider>
	);
};

ReactDOM.render(<MySite />, document.getElementById('root'));

if (module.hot) {
	module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
