import PropTypes from 'prop-types';
import React, { useEffect, Suspense, lazy } from 'react';
import ScrollToTop from './helpers/scroll-top';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import api from 'helpers/api';
import { useRecoilState } from 'recoil';
import { ProductStore } from 'store/states';

// home pages
const Home = lazy(() => import('./pages/home/Home'));
const Shop = lazy(() => import('./pages/shop/Shop'));
const ShopByCategory = lazy(() => import('./pages/shop/ShopCategory'));
const Product = lazy(() => import('./pages/shop-product/Product'));
const Contact = lazy(() => import('./pages/other/Contact'));

const Cart = lazy(() => import('./pages/other/Cart'));
const Wishlist = lazy(() => import('./pages/other/Wishlist'));
const Checkout = lazy(() => import('./pages/other/Checkout'));
const CheckoutConfirmation = lazy(() =>
	import('./pages/other/CheckoutConfirmation')
);

const NotFound = lazy(() => import('./pages/other/NotFound'));

const App = (props) => {
	let [, setProducts] = useRecoilState(ProductStore);

	useEffect(() => {
		props.dispatch(
			loadLanguages({
				languages: {
					en: require('./translations/english.json'),
					fn: require('./translations/french.json'),
					de: require('./translations/germany.json'),
				},
			})
		);
	});

	useEffect(() => {
		api.get('/products').then(({ data }) => {
			data.forEach((p) => {
				p.quantity = p.quantity_us + p.quantity_vn;

				if (!p.images.length) {
					p.images = [
						{
							url:
								'https://www.arraymedical.com/wp-content/uploads/2018/12/product-image-placeholder.jpg',
							name: `${p.id}_placeholder`,
						},
					];
				}
			});
			setProducts(data);
		});
	}, [setProducts]);

	return (
		<ToastProvider placement="bottom-left">
			<BreadcrumbsProvider>
				<Router>
					<ScrollToTop>
						<Suspense
							fallback={
								<div className="flone-preloader-wrapper">
									<div className="flone-preloader">
										<span></span>
										<span></span>
									</div>
								</div>
							}
						>
							<Switch>
								<Route
									exact
									path={process.env.PUBLIC_URL + '/'}
									component={Home}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/home'}
									component={Home}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/shop/:category'}
									component={Shop}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/shop'}
									component={ShopByCategory}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/product/:id'}
									render={(routeProps) => {
										return (
											<Product
												{...routeProps}
												key={routeProps.match.params.id}
											/>
										);
									}}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/contact'}
									component={Contact}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/cart'}
									component={Cart}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/wishlist'}
									component={Wishlist}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/checkout'}
									component={Checkout}
								/>
								<Route
									path={process.env.PUBLIC_URL + '/checkout_confirmation'}
									component={(routeProps) => (
										<CheckoutConfirmation {...routeProps} />
									)}
								/>

								<Route
									path={process.env.PUBLIC_URL + '/not-found'}
									component={NotFound}
								/>

								<Route exact component={NotFound} />
							</Switch>
						</Suspense>
					</ScrollToTop>
				</Router>
			</BreadcrumbsProvider>
		</ToastProvider>
	);
};

App.propTypes = {
	dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
