import { atom, selector } from 'recoil';
import { each, find } from 'lodash';

export const ProductStore = atom({
	key: 'products', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value)
});

export const WishListStore = atom({
	key: 'wishlist', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value)
});

export const CartTrackingStore = atom({
	key: 'carttracking', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value)
});

export const CartStore = selector({
	key: 'card',
	get: ({ get }) => {
		const products = get(ProductStore);
		const cartTracking = get(CartTrackingStore);

		let cart = {
			total: 0,
			items: [],
		};

		each(cartTracking, (item) => {
			// indicate variant selection
			let product = find(products, { id: item.product_id });

			cart.items.push({
				id: product.id,
				name: product.name,
				images: product.images,
				quantity: item.quantity,
				sell_price: product.sell_price,
				line_total: product.sell_price * item.quantity,
			});

			cart.total += item.quantity * product.sell_price;
		});

		return cart;
	},
});

export const WishListItemsStore = selector({
	key: 'wishlistitem',
	get: ({ get }) => {
		const wishlist = get(WishListStore);
		const products = get(ProductStore);

		return products.filter((p) => {
			return wishlist.includes(p.id);
		});
	},
});
