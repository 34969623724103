import Axios from 'axios';

const api = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Authorization: `Basic ${process.env.REACT_APP_API_TOKEN}`,
		'Cache-Control': 'no-cache',
	},
});

export default api;
